<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	Vue.component("HomeSlider", () => import("@frontend/part/HomeSlider.vue"))

	export default {
		extends: BaseVue,
		data() {
			return {
				overview: {}
			}
		},
		computed: {

		},
		async mounted() {
			this.$set(this.$root, 'page', this)
			this.getOverview()
		},
		methods: {
			getOverview() {
				Gen.apirest("/home-overview", {}, (err, resp) => {
					if (err) console.log("overview err" + err)
					this.overview = resp.data
				})
			}
		},
	};
</script>
<template>
	<div>
		<HomeSlider />
		<!-- Content -->
		<section id="content">
			<div class="content-wrap">
				<section class="section nobg nopadding nomargin">
					<div class="container-fluid">
						<div class="box-options">
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-md-6 col-lg-4" v-for="(v,k) in overview" :key="k" data-aos="fade-up"
										id="box1">
										<div :class="'box-option '+v.colorDB">
											<img :src="uploader(v.ash_img)" :alt="v.ash_title" :title="v.ash_title" data-aos="fade-up"
												:data-aos-delay="((k+1)*100)" data-aos-anchor="#box1">
											<div class="bg-text">
												<div class="bgt-title" data-aos="fade-up" :data-aos-delay="((k+1)*200)"
													data-aos-anchor="#box1">
													<h3 :style="'color:'+v.ash_title_color">{{v.ash_title}}</h3>
													<strong :style="'color:'+v.ash_title_sub_color">{{v.ash_sub_title}}</strong>
												</div>
												<div class="bgt-caption" data-aos="fade-up" :data-aos-delay="((k+1)*300)" data-aos-anchor="#box1">
													<p v-html="v.ash_desc"></p>
													<a v-if="v.ash_is_btn=='Y'" :href="v.ash_link" class="box-btn" :target="k == 2 ? '_blank': ''">{{v.ash_text_btn}}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

		</section>
	</div>
</template>